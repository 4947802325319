import { Link } from "gatsby"
import { useStaticQuery,graphql } from "gatsby"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export default ({casetype,currentpath}) => {
  const fil = 'ss'
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            subtitle
            type
          }
        }
      }
    }
  }
`)



const Posts = data.allMarkdownRemark.edges
.filter(edge => { return  !!edge.node.frontmatter.date}) // You can filter your posts based on some criteria
.map(function(edge){

    let liclass=( edge.node.frontmatter.path != currentpath ) ? '':'current';
    

    if(edge.node.frontmatter.type )
return <li class={liclass}><strong><em>{edge.node.frontmatter.subtitle}</em></strong> {edge.node.frontmatter.title} <AniLink cover bg="#000000"  to={edge.node.frontmatter.path} className='readmore'></AniLink ><span className='type'>{edge.node.frontmatter.type}</span></li>
    else
      return <li><strong><em>{edge.node.frontmatter.subtitle}</em></strong> {edge.node.frontmatter.title} <span className='comingsoon'>coming soon</span></li>

  }
)



  return (
    <div id="casestudieslist" className='casestudieslist'>

  <h2>MORE CASE STUDIES</h2>
    <ul>
      {Posts}

    </ul>
  </div>
  )
}


