import React from "react"

import WaypointView from '../components/waypointview'
import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import CSlist from "../components/cslist"
import csimg from "./../images/uviso.png"
const SecondPage = () => (
  <Layout pageclass = 'white'>
    <SEO title="Case Studies" keywords={['webdesign', 'application', 'development']} />
    <WaypointView always="always"><span className='acc'>case </span><span>STUDIES</span></WaypointView>
    <h3 className='headlinedesc'>websites</h3>
    <div className='services textflow'>
    <p className='col75'><em><strong>responsive website</strong></em><br/>
    Ultraviolet is a Shanghai's 3 Michelin Star restaurant offering immersive and multi-sensory dining experience. The goal was to build a website, which will give visitors a chance to preview its unique concept and mood.
    </p>

</div>
<div class='csimage'>
<img src={csimg} alt='case studies - real time ordering applications'/>
   
</div>
    <br /><br />
    <div className='services textflow'>
    <p className='col75'><em><strong>results</strong></em><br/>
    We developed a very visually rich website with non-standard navigation patterns and high-level attention to details. Despite desktop version complexity, instead of just simplifying it, we managed to deliver almost the same experience in a mobile version.
<br/><br/>
Check also related case studies - <strong>UV Booking System</strong>.
    </p>
    </div>
    <div className='services textflow'>
    <p className='col75'><em><strong>about technology</strong></em><br/>
Wordpress + REST Api + Css + JavaScript + Backbone
    </p>
    </div>
    
    <CSlist/>
  </Layout>
)

export default SecondPage
